import Link from '@components/Link';
import React from 'react';
import ArrowDownIcon from '@uikit/Icons/NewDesign/ArrowDown_inline.svg';
import Select from '@uikit/Select';

import styles from './styles.scss';
import { useSelector } from 'react-redux';
import { contextSelector } from '@ducks/application/context';

const items = [
    { name: 'По популярности', query: { sort: 'sell' } },
    { name: 'По новизне', query: { sort: 'new' } },
];

const SortMobile: React.FC = () => {
    const ContextState = useSelector(contextSelector);
    const ContextSortType = ContextState.query?.sort ?? items[0].query.sort;

    const selectItem =
        items.find((item) => item.query.sort === ContextSortType) ?? items[0];

    return (
        <div className={styles['sort']}>
            <Select
                className={styles['select']}
                items={items}
                selectedItem={selectItem}
                renderItem={(item) => {
                    return (
                        <Link
                            context={{
                                ...ContextState,
                                query: item.query,
                            }}
                            nativeLinkProps={{
                                className: styles.sortLinks,
                                'aria-disabled':
                                    ContextSortType === item.query.sort ||
                                    !ContextSortType,
                                onClick: (event) => event.currentTarget.blur(),
                            }}
                            disabled={ContextSortType === item.query.sort}
                        >
                            <span className={styles['link']}>{item.name}</span>
                            <ArrowDownIcon className={styles['icon']} />
                        </Link>
                    );
                }}
            />
        </div>
    );
};

export default SortMobile;
