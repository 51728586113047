import React from 'react';
import useControlled from './useControled';

/*
From https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Pagination/usePagination.jss
but with better typings and logic fixes;
*/

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
};

export interface UsePaginationProps {
    /**
     * Number of always visible pages at the beginning and end.
     * @default 1
     */
    boundaryCount?: number;
    /**
     * The name of the component where this hook is used.
     */
    componentName?: string;
    /**
     * The total number of pages.
     * @default 1
     */
    total?: number;
    /**
     * The page selected by default when the component is uncontrolled.
     * @default 1
     */
    defaultPage?: number;
    /**
     * If `true`, the pagination component will be disabled.
     * @default false
     */
    disabled?: boolean;
    /**
     * If `true`, hide the next-page button.
     * @default false
     */
    hideNextButton?: boolean;
    /**
     * If `true`, hide the previous-page button.
     * @default false
     */
    hidePrevButton?: boolean;
    /**
     * Callback fired when the page is changed.
     *
     * @param {object} event The event source of the callback.
     * @param {number} page The page selected.
     */
    onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
    /**
     * The current page.
     */
    page?: number;
    /**
     * If `true`, show the first-page button.
     * @default false
     */
    showFirstButton?: boolean;
    /**
     * If `true`, show the last-page button.
     * @default false
     */
    showLastButton?: boolean;
    /**
     * Number of always visible pages before and after the current page.
     * @default 1
     */
    siblingCount?: number;
}

export interface UsePaginationItem {
    onClick: React.ReactEventHandler;
    type:
        | 'page'
        | 'first'
        | 'last'
        | 'next'
        | 'previous'
        | 'start-ellipsis'
        | 'end-ellipsis';
    page: number;
    selected: boolean;
    disabled: boolean;
}

export interface UsePaginationResult {
    items: UsePaginationItem[];
}

const usePagination = (props: UsePaginationProps = {}): UsePaginationResult => {
    // keep default values in sync with @default tags in Pagination.propTypes
    const {
        boundaryCount = 1,
        componentName = 'usePagination',
        total = 1,
        defaultPage = 1,
        disabled = false,
        hideNextButton = false,
        hidePrevButton = false,
        onChange: handleChange,
        page: pageProp,
        showFirstButton = false,
        showLastButton = false,
        siblingCount = 1,
        ...other
    } = props;

    const [page, setPageState] = useControlled({
        controlled: pageProp,
        default: defaultPage,
        name: componentName,
        state: 'page',
    });

    const handleClick: UsePaginationProps['onChange'] = (event, value) => {
        if (!pageProp) {
            setPageState(value);
        }
        if (handleChange) {
            handleChange(event, value);
        }
    };

    const startPages = range(1, Math.min(boundaryCount, total));
    const endPages = range(
        Math.max(total - boundaryCount + 1, boundaryCount + 1),
        total
    );

    const siblingsStart = Math.max(
        Math.min(
            // Natural start
            page - siblingCount,
            // Lower boundary when page is high
            total - boundaryCount - siblingCount * 2 - 1
        ),
        // Greater than startPages
        boundaryCount + 2
    );

    const siblingsEnd = Math.min(
        Math.max(
            // Natural end
            page + siblingCount,
            // Upper boundary when page is low
            boundaryCount + siblingCount * 2 + 2
        ),
        // Less than endPages
        endPages[0] - 2
    );

    // Basic list of items to render
    // e.g. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
    const itemList: (UsePaginationItem['type'] | number)[] = [
        ...(showFirstButton ? (['first'] as const) : []),
        ...(hidePrevButton ? [] : (['previous'] as const)),
        ...startPages,

        // Start ellipsis
        // eslint-disable-next-line no-nested-ternary
        ...(siblingsStart > boundaryCount + 2
            ? (['start-ellipsis'] as const)
            : boundaryCount + 1 < total - boundaryCount
            ? [boundaryCount + 1]
            : []),

        // Sibling pages
        ...range(siblingsStart, siblingsEnd),

        // End ellipsis
        // eslint-disable-next-line no-nested-ternary
        ...(siblingsEnd < total - boundaryCount - 1
            ? (['end-ellipsis'] as const)
            : total - boundaryCount > boundaryCount
            ? [total - boundaryCount]
            : []),

        ...endPages,
        ...(hideNextButton ? [] : (['next'] as const)),
        ...(showLastButton ? (['last'] as const) : []),
    ];

    // Map the button type to its page number
    const buttonPage = (type: UsePaginationItem['type']) => {
        switch (type) {
            case 'first':
                return 1;
            case 'previous':
                return page - 1;
            case 'next':
                return page + 1;
            case 'last':
                return total;
            default:
                return page;
        }
    };

    // Convert the basic item list to PaginationItem props objects
    const items = itemList.map<UsePaginationItem>((item) => {
        return typeof item === 'number'
            ? {
                  onClick: (event) => {
                      handleClick(event, item);
                  },
                  type: 'page',
                  page: item,
                  selected: item === page,
                  disabled,
                  'aria-current': item === page ? 'true' : undefined,
              }
            : {
                  onClick: (event) => {
                      handleClick(event, buttonPage(item));
                  },
                  type: item,
                  page: buttonPage(item),
                  selected: false,
                  disabled:
                      disabled ||
                      (item.indexOf('ellipsis') === -1 &&
                          (item === 'next' || item === 'last'
                              ? page >= total
                              : page <= 1)),
              };
    });

    return {
        items,
        ...other,
    };
};

export default usePagination;
