import clsx from 'clsx';
import React from 'react';
import { RectShape, TextRow } from 'react-placeholder/lib/placeholders';
import styles from './CatalogItemSkeleton.module.scss';

const CatalogItemSkeleton = () => (
    <div className={styles.skeleton}>
        <RectShape
            className={clsx(styles.image, styles.animated)}
            style={{ height: 'inherit', backgroundColor: 'inherit' }}
        />
        <TextRow
            color="#C4C4C4"
            style={{
                width: '75%',
                backgroundColor: 'inherit',
            }}
            className={clsx(styles.text, styles.animated)}
        />
        <TextRow
            color="#C4C4C4"
            style={{
                width: '50%',
                backgroundColor: 'inherit',
            }}
            className={clsx(styles.text, styles.animated)}
        />
    </div>
);

export default CatalogItemSkeleton;
