import Link from '@components/Link';
import { contextSelector, ContextState } from '@ducks/application/context';
import { structureSelector } from '@ducks/application/structure';
import { tagsSelector } from '@ducks/application/tags';
import Button from '@uikit/Button';
import { CONTEXT } from '@vsemayki/url-resolver';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import CatImage from './images/cat_inline.svg';
import styles from './not_found.module.scss';
import CatalogNotFoundItems from '@components/Catalog/NotFound/not_found';
import { catalogSelector } from '@ducks/catalog';

type NotFoundButtonProps = { context: ContextState };
const NotFoundButton: React.FC<NotFoundButtonProps> = (props) => (
    <Link
        context={props.context}
        nativeLinkProps={{ className: styles.not_found__navigaton__button }}
    >
        <Button
            color="primary"
            variant="filled"
            tabIndex={-1}
            className={styles.not_found__navigaton__button}
        >
            {props.children}
        </Button>
    </Link>
);

type CatalogNotFoundProps = JSX.IntrinsicElements['section'];
const CatalogNotFound = (props: CatalogNotFoundProps) => {
    const ContextState = useSelector(contextSelector);
    const TagsState = useSelector(tagsSelector);
    const StructureState = useSelector(structureSelector);
    const CatalogState = useSelector(catalogSelector);

    const ContextMapped = {
        category:
            ContextState.collection &&
            TagsState.find((el) => el.id === ContextState.collection?.category),
        subcategory:
            ContextState.collection &&
            TagsState.find(
                (el) =>
                    el.id ===
                    `${ContextState.collection?.category}__${ContextState.collection?.subCategory}`
            ),
        structure:
            ContextState.filter?.value &&
            StructureState.find(
                (struct) => struct.id === ContextState.filter?.value
            ),
    };

    if (
        (ContextMapped && CatalogState.collectionItems.length > 0) ||
        CatalogState.categoryItems.length > 0
    ) {
        return <CatalogNotFoundItems />;
    }
    return (
        <section {...props} className={clsx(styles.not_found, props.className)}>
            {ContextState.name === 'catalogue' && !CatalogState.loading && (
                <div className={styles.not_found__wrapper}>
                    <CatImage />
                    <div className={styles.not_found__title}>
                        <h2 className={styles.not_found__title__header}>
                            <b>
                                К сожалению, на данный момент нет доступных
                                принтов 😔
                            </b>
                        </h2>
                    </div>
                    <div className={styles.not_found__navigaton}>
                        <NotFoundButton context={{ name: CONTEXT.constructor }}>
                            Создать товар
                        </NotFoundButton>
                    </div>
                </div>
            )}
        </section>
    );
};

export default CatalogNotFound;
