import React from 'react';
import Link from '@components/Link';
import { useSelector } from 'react-redux';
import { contextSelector } from '@ducks/application/context';
import * as styles from './styles.scss';
import ArrowDownIcon from '@uikit/Icons/NewDesign/ArrowDown_inline.svg';
import useMobileLayout from '@src/hooks/useMobileLayout';
import SortMobile from './SortMobile';

type SortProps = JSX.IntrinsicElements['div'];
const Sort = (props: SortProps) => {
    const ContextState = useSelector(contextSelector);
    const ContextSortType = ContextState.query?.sort;

    const isMobile = useMobileLayout();

    if (isMobile) {
        return (
            <div {...props}>
                <SortMobile />
            </div>
        );
    }

    return (
        <div {...props}>
            <Link
                context={{ ...ContextState, query: { sort: 'sell' } }}
                nativeLinkProps={{
                    className: styles.sortLinks,
                    'aria-disabled':
                        ContextSortType === 'sell' || !ContextSortType,
                    onClick: (event) => event.currentTarget.blur(),
                }}
                disabled={ContextSortType === 'sell' || !ContextSortType}
            >
                По популярности{' '}
                {(ContextSortType === 'sell' || !ContextSortType) && (
                    <ArrowDownIcon />
                )}
            </Link>
            <Link
                context={{ ...ContextState, query: { sort: 'new' } }}
                nativeLinkProps={{
                    className: styles.sortLinks,
                    'aria-disabled': ContextSortType === 'new',
                    onClick: (event) => event.currentTarget.blur(),
                }}
                disabled={ContextSortType === 'new'}
            >
                По новизне {ContextSortType === 'new' && <ArrowDownIcon />}
            </Link>
        </div>
    );
};

export default Sort;
