import * as React from 'react';
import Link from '@components/Link';
import { contextSelector } from '@ducks/application/context';
import { StructureItem } from '@ducks/application/structure';
import { CONTEXT, ContextData } from '@vsemayki/url-resolver';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import styles from './filterItem.module.scss';
import ChevronLeftIcon from '@uikit/Icons/NewDesign/ChevronLeft_inline.svg';
import useDebounce from '@src/hooks/useDebounce';

interface FilterItemProps {
    items: StructureItem[];
    title: string;
    currentItem?: StructureItem;
}

const FilterItem = (props: FilterItemProps) => {
    const ContextState = useSelector(contextSelector);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleSetOpen = useDebounce(setIsOpen, { delay: 127 });

    const isProductType = props.currentItem?.type === 't';

    return (
        <span
            className={clsx(
                styles.filterItem,
                isOpen && !isProductType && styles.filterItemActive
            )}
            tabIndex={isProductType ? -1 : 0}
            onFocus={() => {
                if (isProductType) return;
                handleSetOpen(true);
            }}
            onBlur={() => {
                if (isProductType) return;
                handleSetOpen(false);
            }}
            onClick={() => {
                if (isProductType) return;
                handleSetOpen((prevState) => !prevState);
            }}
        >
            <div className={styles.filterItemWrapper}>
                <span className={styles.filterItemText}>{props.title}</span>{' '}
                <ChevronLeftIcon className={styles.filterItemArrow} />
            </div>
            <div
                className={styles.linksWrapper}
                onPointerOver={() => {
                    handleSetOpen.cancel();
                    handleSetOpen(true);
                }}
            >
                <div
                    className={clsx(styles.links, isOpen && styles.linksActive)}
                >
                    {props.items.map((item) => {
                        const linkContext: ContextData = {
                            name: CONTEXT.catalogue,
                            query: {
                                ...ContextState.query,
                                page: 1,
                            },
                            collection: ContextState.collection,
                            filter: { type: item.type, value: item.id },
                            custom: {
                                ...ContextState.custom,
                            },
                        };
                        return (
                            <Link
                                key={item.id}
                                context={linkContext}
                                disabled={props.currentItem?.id === item.id}
                                nativeLinkProps={{
                                    className: clsx(
                                        styles.linkItem,
                                        props.currentItem?.id === item.id &&
                                            styles.activeItem
                                    ),
                                }}
                            >
                                {item.title}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </span>
    );
};

export default FilterItem;
