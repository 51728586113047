import React from 'react';
import Title from '@uikit/Title';
import useContextMapper from '@src/hooks/useContextMapper';
import { useSelector } from 'react-redux';
import { contextSelector } from '@ducks/application/context';

type CatalogTitleProps = React.ComponentProps<typeof Title>;
const CatalogTitle = (props: CatalogTitleProps) => {
    const ContextMapped = useContextMapper();
    const { custom } = useSelector(contextSelector);

    let newTitle = 'Каталог';

    if (ContextMapped.collection) {
        newTitle = ContextMapped.collection.title;
    }

    if (ContextMapped.subcategory) {
        newTitle = ContextMapped.subcategory.title;
    }

    if (ContextMapped.structure) {
        newTitle = ContextMapped.structure.title;
    }

    if (ContextMapped.collection && ContextMapped.structure) {
        newTitle = `${ContextMapped.structure.title} "${
            ContextMapped.subcategory?.title ?? ContextMapped.collection.title
        }"`;
    }

    if (custom?.search_page) {
        newTitle = `Все с "${decodeURIComponent(custom.search_page)}"`;
    }

    return <Title {...props}>{newTitle}</Title>;
};

export default CatalogTitle;
