import * as React from 'react';
import { useSelector } from 'react-redux';
import { contextSelector } from '@store/ducks/application/context';
import * as styles from './styles.scss';
import { structureSelector, StructureItem } from '@ducks/application/structure';
import { CreateStructureWithChildrens, CreateStructureTree } from './utils';
import FilterItem from './item';
import { breadcrumbsSelector } from '@ducks/application/breadcrumbs';
import { CONTEXT, ContextData } from '@vsemayki/url-resolver';
import Link from '@components/Link';
import clsx from 'clsx';

const CatalogFilter: React.FC = () => {
    const ContextState = useSelector(contextSelector);
    const StructureState = useSelector(structureSelector);
    const BreadcrumbsState = useSelector(breadcrumbsSelector);

    const structureClone = React.useMemo(() => [...StructureState], [
        StructureState,
    ]);

    const filteredBread = BreadcrumbsState.filter(
        (crumb) => crumb.context.filter
    );

    const structureTree = React.useMemo(
        () => CreateStructureTree(structureClone),
        [structureClone]
    );

    const structureObj = React.useMemo(
        () => CreateStructureWithChildrens(structureClone),
        [structureClone]
    );

    const [filterState, setFilterState] = React.useState<StructureItem[]>([]);

    React.useEffect(() => {
        const structureItemFilter = (
            item: StructureItem | undefined
        ): item is StructureItem => {
            return typeof item !== 'undefined';
        };

        const initState = filteredBread
            .flatMap((item) =>
                structureClone.find((struct) => {
                    return (
                        struct.id ===
                        (item.context.collection?.category ||
                            item.context.filter?.value)
                    );
                })
            )
            .filter(structureItemFilter);
        setFilterState(initState ?? []);
    }, [BreadcrumbsState]);

    const currentItem = structureClone.find(
        (item) => item.id === ContextState.filter?.value
    );

    const filteredStructure = structureClone
        .filter((item) => {
            if (ContextState.filter === undefined) {
                return item.parent === undefined;
            }

            if (
                ContextState.filter.type === 'g' ||
                ContextState.filter.type === 'v'
            ) {
                return item.parent === ContextState.filter.value;
            }

            if (ContextState.filter.type === 't') {
                return item.parent === currentItem?.parent;
            }
        })
        .sort(
            (itemA, itemB) =>
                parseInt(String(itemA.position)) -
                parseInt(String(itemB.position))
        );

    return (
        <>
            <div className={clsx(styles.parentFilters)}>
                {filterState.map((item) => {
                    const curItem = structureObj[item.id];
                    const parrent = curItem.parent
                        ? structureObj[curItem.parent].children
                        : structureTree;

                    if (!curItem) return null;

                    return (
                        <FilterItem
                            key={item.id}
                            currentItem={curItem}
                            items={parrent}
                            title={curItem?.title}
                        />
                    );
                })}
            </div>
            <div className={styles.filters}>
                {filteredStructure.map((item) => {
                    const linkContext: ContextData = {
                        name: CONTEXT.catalogue,
                        query: {
                            ...ContextState.query,
                            page: 1,
                        },
                        collection: ContextState.collection,
                        filter: { type: item.type, value: item.id },
                        custom: {
                            ...ContextState.custom,
                        },
                    };
                    return (
                        <Link
                            key={item.id}
                            context={linkContext}
                            disabled={currentItem?.id === item.id}
                            nativeLinkProps={{
                                className: clsx(
                                    styles.linkItem,
                                    currentItem?.id === item.id &&
                                        styles.activeItem
                                ),
                            }}
                        >
                            {item.title}
                        </Link>
                    );
                })}
            </div>
        </>
    );
};

export default CatalogFilter;
