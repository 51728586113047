import Link from '@components/Link';
import { contextSelector, ContextState } from '@ducks/application/context';
import { structureSelector } from '@ducks/application/structure';
import { tagsSelector } from '@ducks/application/tags';
import Button from '@uikit/Button';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@uikit/Icons/closeBtn_inline.svg';
import styles from './not_found.module.scss';
import CatalogItemSkeleton from '@components/Catalog/CatalogItem/Skeleton';
import CatalogItem from '@components/Catalog/CatalogItem';
import { catalogSelector } from '@ducks/catalog';

type NotFoundButtonProps = { context: ContextState };
const NotFoundButton: React.FC<NotFoundButtonProps> = (props) => (
    <Link
        context={props.context}
        nativeLinkProps={{ className: styles.not_found__navigaton__button }}
    >
        <Button
            color="primary"
            variant="filled"
            tabIndex={-1}
            className={styles.not_found__navigaton__button}
        >
            {props.children}
        </Button>
    </Link>
);

const CatalogNotFoundItems = () => {
    const ContextState = useSelector(contextSelector);
    const TagsState = useSelector(tagsSelector);
    const StructureState = useSelector(structureSelector);
    const CatalogState = useSelector(catalogSelector);

    const ContextMapped = {
        category:
            ContextState.collection &&
            TagsState.find((el) => el.id === ContextState.collection?.category),
        subcategory:
            ContextState.collection &&
            TagsState.find(
                (el) =>
                    el.id ===
                    `${ContextState.collection?.category}__${ContextState.collection?.subCategory}`
            ),
        structure:
            ContextState.filter?.value &&
            StructureState.find(
                (struct) => struct.id === ContextState.filter?.value
            ),
    };
    const [showButton, setShow] = useState(true);
    const handleClick = () => {
        setShow(false);
    };
    const Placeholders = useMemo(
        () =>
            Array.from(Array(CatalogState.options.limit).keys()).map((v) => (
                <CatalogItemSkeleton key={v} />
            )),
        [CatalogState.options.limit]
    );

    const CatalogItemsCollection = useMemo(() => {
        return CatalogState.collectionItems.map((item) => (
            <CatalogItem item={item} key={item.id} />
        ));
    }, [CatalogState.collectionItems]);

    const CatalogItemsCategory = useMemo(() => {
        return CatalogState.categoryItems.map((item) => (
            <CatalogItem item={item} key={item.id} />
        ));
    }, [CatalogState.categoryItems]);

    return (
        <React.Fragment>
            {showButton && (
                <div className={styles.not_found__info}>
                    <div className={styles.icon} />
                    Ничего не найдено. Попробуйте изменить параметры фильтрации
                    или посмотрите похожие товары ниже.
                    <div className={styles.icon__close} onClick={handleClick}>
                        <CloseIcon />
                    </div>
                </div>
            )}

            {ContextMapped.structure && CatalogItemsCategory.length > 0 && (
                <React.Fragment>
                    <div className={styles.not_found__items}>
                        <h2>
                            Категория{' '}
                            <Link
                                context={{
                                    ...ContextState,
                                    collection: undefined,
                                    query: undefined,
                                }}
                                nativeLinkProps={{
                                    className:
                                        styles.not_found__navigaton__link,
                                }}
                            >
                                {ContextMapped.structure.title}
                            </Link>
                        </h2>
                    </div>
                    {CatalogState.loading ? Placeholders : CatalogItemsCategory}
                    <div className={styles.not_found__button}>
                        <NotFoundButton
                            context={{
                                ...ContextState,
                                collection: undefined,
                                query: undefined,
                            }}
                        >
                            Смотреть все
                        </NotFoundButton>
                    </div>
                </React.Fragment>
            )}

            {ContextMapped.category && CatalogItemsCollection.length > 0 && (
                <React.Fragment>
                    <div className={styles.not_found__items}>
                        <h2>
                            Коллекция{' '}
                            <Link
                                context={{
                                    ...ContextState,
                                    filter: undefined,
                                    query: undefined,
                                }}
                                nativeLinkProps={{
                                    className:
                                        styles.not_found__navigaton__link,
                                }}
                            >
                                {ContextMapped.subcategory?.title ??
                                    ContextMapped.category.title}
                            </Link>
                        </h2>
                    </div>
                    {CatalogState.loading
                        ? Placeholders
                        : CatalogItemsCollection}
                    <div className={styles.not_found__button}>
                        <NotFoundButton
                            context={{
                                ...ContextState,
                                filter: undefined,
                                query: undefined,
                            }}
                        >
                            Смотреть все
                        </NotFoundButton>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default CatalogNotFoundItems;
