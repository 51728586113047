import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';
import { catalogOptionsSelector, getCatalogItemsLazy } from '@ducks/catalog';
import { build, resolve } from '@vsemayki/url-resolver';
import { contextActions } from '@ducks/application/context';
import { KoaAppState, LiteShopContext } from 'index';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import { useRouter } from 'next/router';
import styles from './navigation.module.scss';
import clsx from 'clsx';
import useIsInView from '@src/hooks/useIsInView';
import { useSelector } from 'react-redux';

type CatalogNavigationProps = JSX.IntrinsicElements['div'];
const CatalogNavigation: React.FC<CatalogNavigationProps> = (props) => {
    const router = useRouter();
    const dispatch = useTypedDispatch();
    const CatalogOptionsState = useSelector(catalogOptionsSelector);

    const { limit, offset, total_items } = CatalogOptionsState;
    const noMoreItems = total_items - offset < limit;

    //1. disable lazyload if user start from page > 2 or if there is no items in catalog to load;
    const [disableLazyload, setDisableLazyload] = useState<boolean>(() => {
        return noMoreItems || Boolean(offset);
    });

    const [showMoreCounter, setShowMoreCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [observerRef, isInView] = useIsInView<HTMLDivElement>();

    useEffect(() => {
        //2. if user navigates to different category, he'll start from 0 page and in this case soft reset lazyload
        if (!CatalogOptionsState.offset) {
            setShowMoreCounter(0);
            setDisableLazyload(noMoreItems);
        }
    }, [CatalogOptionsState.offset, noMoreItems]);

    const handleShowMore = async () => {
        setShowMoreCounter((prev) => prev + 1);

        setIsLoading(true);

        const curContextData: LiteShopContext = resolve(
            router.asPath
        ).getContextData();

        //build link for next page
        const newHref = build({
            ...curContextData,
            query: {
                ...curContextData.query,
                page: (curContextData.query?.page ?? 1) + 1,
            },
        });

        const newContext: KoaAppState['context'] = resolve(newHref);
        const newContextData = newContext.getContextData();

        //get new items
        await dispatch(getCatalogItemsLazy(newContext));

        //change link in url
        await router.push(newContextData.name, newHref, { shallow: true });

        //update context in redux
        dispatch(contextActions.setContextData(newContextData));

        setIsLoading(false);
    };

    useEffect(() => {
        //3. handle lazyload when observer in view
        if (
            !noMoreItems &&
            !disableLazyload &&
            isInView &&
            showMoreCounter < 2
        ) {
            handleShowMore();
        }
    }, [isInView]);

    return (
        <div {...props} className={clsx(props.className)}>
            <nav className={styles.navigation}>
                <div className={styles.observer} ref={observerRef} />
                {!noMoreItems && (
                    <span
                        onClick={handleShowMore}
                        className={clsx(
                            styles.showMore,
                            isLoading || (noMoreItems && styles.disable)
                        )}
                    >
                        Показать ещё
                    </span>
                )}
                {(showMoreCounter >= 2 || disableLazyload) && <Pagination />}
            </nav>
        </div>
    );
};

export default CatalogNavigation;
