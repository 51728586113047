import CatalogItem from '@components/Catalog/CatalogItem';
import CatalogItemSkeleton from '@components/Catalog/CatalogItem/Skeleton';
import CatalogFilter from '@components/Catalog/Filter/Desktop';
import CatalogMobileControls from '@components/Catalog/MobileControls';
import CatalogNavigation from '@components/Catalog/Navigation';
import CatalogNotFound from '@components/Catalog/NotFound';
import Sort from '@components/Catalog/Sort';
import CatalogTitle from '@components/Catalog/Title';
import Categories from '@components/Categories';
import { lazyLoadingSelector } from '@ducks/application/lazyLoading';
import {
    shopSettingsIsLeftMenuHiddenSelector,
    shopSettingsSelector,
} from '@ducks/application/shopSettings';
import { structureSelector } from '@ducks/application/structure';
import { tagsSelector } from '@ducks/application/tags';
import useMobileLayout from '@src/hooks/useMobileLayout';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import * as styles from '@src/scss/pages/catalog/styles.scss';
import { buildBreadcrumbs } from '@store/ducks/application/breadcrumbs';
import { contextSelector } from '@store/ducks/application/context';
import { catalogSelector, getCatalogItems } from '@store/ducks/catalog';
import { build, resolve } from '@vsemayki/url-resolver';
import clsx from 'clsx';
import { NextComponentType } from 'next';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { KoaAppState } from '../index';
import { AppContextWithStore } from './_app';

type CatalogPageProps = unknown;

const CatalogPage: NextComponentType<
    AppContextWithStore,
    CatalogPageProps,
    CatalogPageProps
> = () => {
    const isMounted = useRef<boolean>(true);
    const CatalogState = useSelector(catalogSelector);
    const LazyLoadingState = useSelector(lazyLoadingSelector);
    const isLeftMenuHidden = useSelector(shopSettingsIsLeftMenuHiddenSelector);
    const isMobile = useMobileLayout();
    const dispatch = useTypedDispatch();
    const ContextState = useSelector(contextSelector);
    const haveItems = CatalogState.items.length > 0;
    const TagsState = useSelector(tagsSelector);
    const StructureState = useSelector(structureSelector);
    const { catalogDisplayColumns } = useSelector(shopSettingsSelector);
    const classColumsItem = (() => {
        switch (catalogDisplayColumns) {
            case 4:
                return styles.fourColumsItem;
            case 5: {
                return styles.fiveColumsItem;
            }
            default:
                return null;
        }
    })();
    const Placeholders = useMemo(
        () =>
            Array.from(Array(CatalogState.options.limit).keys()).map((v) => (
                <CatalogItemSkeleton key={v} />
            )),
        [CatalogState.options.limit]
    );

    const ContextMapped = {
        category:
            ContextState.collection &&
            TagsState.find((el) => el.id === ContextState.collection?.category),
        subcategory:
            ContextState.collection &&
            TagsState.find(
                (el) =>
                    el.id ===
                    `${ContextState.collection?.category}__${ContextState.collection?.subCategory}`
            ),
        structure:
            ContextState.filter?.value &&
            StructureState.find(
                (struct) => struct.id === ContextState.filter?.value
            ),
    };

    useEffect(() => {
        if (!isMounted.current) return;

        let categoryHref = '';
        let collectionHref = '';
        if (CatalogState.items.length === 0) {
            if (ContextMapped.category || ContextMapped.subcategory) {
                collectionHref = build({
                    name: 'catalogue',
                    collection: ContextState.collection,
                });
                const collectionContext: KoaAppState['context'] = resolve(
                    collectionHref
                );
                dispatch(getCatalogItems(collectionContext, 'collection'));
            }
            if (ContextMapped.structure) {
                categoryHref = build({
                    name: 'catalogue',
                    filter: ContextState.filter,
                });
                const categoryContext: KoaAppState['context'] = resolve(
                    categoryHref
                );
                dispatch(getCatalogItems(categoryContext, 'category'));
            }
        }
        return () => {
            isMounted.current = false;
        };
    }, [ContextState, CatalogState.items.length]);

    const CatalogItems = useMemo(() => {
        return CatalogState.items.map((item) => (
            <CatalogItem item={item} key={item.id} />
        ));
    }, [CatalogState.items]);

    const CatalogContent = () => {
        return haveItems ? (
            <>
                <Sort className={styles.sort} />
                {CatalogState.loading ? Placeholders : CatalogItems}

                {LazyLoadingState && Placeholders}

                <CatalogNavigation className={styles.pagination} />
            </>
        ) : (
            <CatalogNotFound className={styles.not_found} />
        );
    };

    if (isMobile) {
        return (
            <>
                <div className={styles.title}>
                    <CatalogTitle className={styles.title} />
                </div>
                <CatalogMobileControls className={styles.mobileFilters} />
                <div
                    className={clsx(
                        styles.catalogItems,
                        styles.catalogItemsMobile
                    )}
                >
                    <CatalogContent />
                </div>
            </>
        );
    }

    return (
        <>
            {!isLeftMenuHidden && (
                <div className={clsx(styles.categories, classColumsItem)}>
                    <Categories />
                </div>
            )}
            <div
                className={clsx(
                    styles.catalogItems,
                    isLeftMenuHidden && styles.catalogItems__fullwidth,
                    classColumsItem
                )}
            >
                <div className={styles.title}>
                    <CatalogTitle />
                </div>
                <div className={styles.filter}>
                    <CatalogFilter />
                </div>
                <CatalogContent />
            </div>
        </>
    );
};

CatalogPage.getInitialProps = async (context) => {
    const reduxStore = context.reduxStore;
    const ContextClass = context.ContextClass;

    await reduxStore.dispatch(getCatalogItems(ContextClass));
    reduxStore.dispatch(
        buildBreadcrumbs({
            tag: ContextClass.getCategoryAlias(),
            product: ContextClass.getFilterValue(),
        })
    );
};

export default CatalogPage;
