import React, { ReactNode } from 'react';
import { useSelect, UseSelectProps } from 'downshift';
import ArrowDownIcon from '@src/components/UIKit/Icons/arrowDown_inline.svg';
import * as styles from './styles.scss';
import clsx from 'clsx';

type SelectProps<Item extends ReactNode> = {
    items: Item[];
    placeholder?: ReactNode;
    onSelectedItemChange?: UseSelectProps<Item>['onSelectedItemChange'];
    renderItem?: (item: Item) => ReactNode;
    selectedItem?: Item;
    className?: string;
    id?: string;
};

const Select = <Item extends ReactNode>(props: SelectProps<Item>) => {
    const {
        items,
        placeholder = '',
        renderItem,
        selectedItem: selectedItemProps,
        id,
        className,
    } = props;
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items,
        onSelectedItemChange: props.onSelectedItemChange,
        selectedItem: selectedItemProps,
        toggleButtonId: id,
    });

    const Placeholder = () => {
        if (selectedItem && typeof renderItem === 'function')
            return renderItem(selectedItem);

        return (
            selectedItem || placeholder || (items.length > 0 && items[0]) || ''
        );
    };

    return (
        <div className={clsx(styles.wrapper, className)}>
            <button
                type="button"
                {...getToggleButtonProps({
                    className: clsx(styles.select),
                })}
            >
                <span className={styles.placeholder}>{Placeholder()}</span>
                <ArrowDownIcon
                    className={clsx(styles.arrowDown, isOpen && styles.arrowUp)}
                />
            </button>
            <ul
                {...getMenuProps({
                    className: clsx(styles.list, isOpen && styles.listActive),
                })}
            >
                {items.map((item, idx) => (
                    <li
                        className={clsx(styles.listItem, {
                            [styles.highlighted]: highlightedIndex === idx,
                        })}
                        key={`${item}${idx}`}
                        {...getItemProps({ item, index: idx })}
                    >
                        {renderItem ? renderItem(item) : item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Select;
