import React from 'react';
import * as styles from './styles.scss';
import usePagination, { UsePaginationItem } from '@src/hooks/usePagination';
import Button from '@uikit/Button';
import RightArrowIcon from '@uikit/Icons/NewDesign/ChevronRight_inline.svg';
import LeftArrowIcon from '@uikit/Icons/NewDesign/ChevronLeft_inline.svg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { contextSelector } from '@store/ducks/application/context';
import { catalogOptionsSelector } from '@store/ducks/catalog';
import Link from '@components/Link';

const getIcon = (type: UsePaginationItem['type']) => {
    switch (type) {
        case 'next':
            return <RightArrowIcon className={styles.next} />;
        case 'previous':
            return <LeftArrowIcon className={styles.prev} />;
        default:
            console.error('No case handled for icon', type);
            return null;
    }
};

type PaginationProps = JSX.IntrinsicElements['nav'];

const Pagination: React.FC<PaginationProps> = ({ ...navProps }) => {
    const ContextState = useSelector(contextSelector);
    const CatalogStateOptions = useSelector(catalogOptionsSelector);
    const totalPages =
        Math.ceil(
            CatalogStateOptions.total_items / CatalogStateOptions.limit
        ) || 1;

    const { items } = usePagination({
        total: totalPages,
        page: ContextState.query?.page ?? 1,
    });

    if (totalPages <= 1) return null;

    return (
        <nav {...navProps}>
            <ul className={styles.pagination}>
                {items.map(
                    (
                        { page, type, selected, disabled, ...restItem },
                        index
                    ) => {
                        let children: React.ReactNode = null;
                        switch (type) {
                            case 'start-ellipsis':
                            case 'end-ellipsis':
                                children = (
                                    <span className={styles.dots}>...</span>
                                );
                                break;

                            default:
                                children = (
                                    <Link
                                        context={{
                                            ...ContextState,
                                            query: {
                                                ...ContextState.query,
                                                page,
                                            },
                                        }}
                                        disabled={disabled || selected}
                                    >
                                        <Button
                                            type="button"
                                            className={clsx(styles.page, {
                                                [styles.pageCurrent]: selected,
                                                [styles.next]: type === 'next',
                                                [styles.prev]:
                                                    type === 'previous',
                                            })}
                                            tabIndex={-1}
                                            {...restItem}
                                            aria-label={type}
                                            disabled={disabled}
                                        >
                                            {type === 'page'
                                                ? page
                                                : getIcon(type)}
                                        </Button>
                                    </Link>
                                );
                                break;
                        }

                        return <li key={index}>{children}</li>;
                    }
                )}
            </ul>
        </nav>
    );
};

export default React.memo(Pagination);
